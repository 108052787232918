

import {
  ControlElement, isStringControl, JsonFormsRendererRegistryEntry, rankWith,
} from "@jsonforms/core";
import { defineComponent, toRefs, nextTick } from "vue";
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import { useVanillaControl } from "@jsonforms/vue-vanilla";
import ControlWrapper from "@/core/json-forms/controls/ControlWrapper.vue";
import {
  getType, createHTMLSpanElement, getCaretPosition, setCaretPosition,
} from "@/core/json-forms/components/service";
import RendererConfigurations from "@/core/json-forms/renderer-configurations";
import { labelConvert } from "@/core/util/variable-label-convert";
import {
  getTooltipForMetaoutput, getTooltipforOutput, getTooltipForUdv,
} from "@/flow/application/useTooltipForTags";
import { parseJSONWithStringNumbers } from "@/flow/application/jsonParseForNumbers";
import FunctionSelector from "../components/FunctionSelector.vue";

export type Items = {
  name: string,
  hasChild: boolean,
  type: string,
  childList: Child[],
}

export type Child = {
  name: string,
  childType: string,
}

const controlRenderer = defineComponent({
  name: RendererConfigurations.StringControlRenderer.name,
  components: {
    FunctionSelector,
    ControlWrapper,
  },
  emits: ["update", "updatePopUpOpen", "handleClickEvent", "expressionDrawer"],
  data() {
    return {
      openDialog: false,
      inputMapperDialog: false,
      oldPath: "",
      selectedOutputId: "",
      selectedMetaoutputId: "",
      trackState: false,
      loading: false,
      inputList: [],
      inputDiv: "",
      count: 0,
      currentID: "",
      isContain: false,
      visible: false,
      outputs: toRefs(this.$props).outputList,
      metaoutputs: toRefs(this.$props).metaoutputList,
      systemValues: toRefs(this.$props).systemVariables,
      userDefinedVariablesList: toRefs(this.$props).userDefinedVariables,
      showModal: false,
      clickedFunctionName: "",
      selectedVariables: [],
    };
  },
  computed: {
    RendererConfigurations() {
      return RendererConfigurations;
    },
    stepIndex(): number {
      if (this.selectedOutputId !== undefined) {
        const data = this.outputList.find((item: any) => item.ix === this.selectedOutputId);
        return data ? data.index : -1;
      }
      return -2;
    },
    stepIndexMetaoutput(): number {
      if (this.selectedMetaoutputId !== undefined) {
        const data = this.metaoutputList.find((item: any) => item.ix === this.selectedMetaoutputId);
        return data ? data.index : -1;
      }
      return -2;
    },
  },
  props: {
    stepIdx: Number,
    outputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    userDefinedVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    systemVariables: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    expressions: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    metaoutputList: {
      required: false,
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    showOtherInputs: {
      required: false,
      type: Boolean,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: true,
    },
    ...rendererProps<ControlElement>(),
  },
  setup(props: any) {
    return useVanillaControl(useJsonFormsControl(props));
  },
  methods: {
    convertToInputString(isNotFirstRender = true) {
      try {
        const inputArray = [];
        this.$refs.div.childNodes.forEach((child) => {
          if (child instanceof HTMLBRElement) {
            inputArray.push({
              name: Array(1).fill("\n").join(""),
              type: "input",
              function: "",
            });
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            inputArray.push({
              name: child.getAttribute("name"),
              type: child.getAttribute("type"),
              function: child.getAttribute("function"),
            });
          } else if (child.nodeType === Node.TEXT_NODE
              && (child.textContent !== "" && child.textContent !== "\u200B")) {
            inputArray.push({
              name: child.textContent.replaceAll("\u200B", ""),
              type: "input",
            });
          }
        });
        // An extra line added to make the UI appear is removed here OT-1989
        if (inputArray.length > 0 && inputArray[inputArray.length - 1].name === "\n") {
          inputArray.pop();
        }
        this.selectedVariables = inputArray;
        this.control.data = JSON.stringify(inputArray);
        this.$refs.inputField.value = JSON.stringify(inputArray);
        if (isNotFirstRender) {
          const evt = new Event("change", {
            bubbles: true,
            cancelable: false,
          });
          document.getElementById(`${this.control.id}-input`)
            .dispatchEvent(evt);
        }
      } catch (e) {
        console.log(e, "string");
      }
    },
    setCaret(eventCode = null) {
      const el = document.getElementById(`${this.control.id}-div`);
      if (el) {
        // if node is text node this lines set the caret position OT-1989
        const textNode = this.findFirstTextNode(el);
        if (textNode && eventCode !== "Enter") {
          const textLength = textNode.textContent.length;
          const offset = Math.min(8, textLength);
          const range = document.createRange();
          const sel = window.getSelection();

          range.setStart(textNode, offset);
          range.collapse(true);

          sel.removeAllRanges();
          sel.addRange(range);
        } else if (eventCode === "Enter") {
          // if pressed key is Enter this lines set the caret position OT-1989
          const range = document.createRange();
          const sel = window.getSelection();
          const { lastChild } = el;
          if (lastChild.nodeType === Node.TEXT_NODE) {
            range.setStart(lastChild, lastChild.textContent.length);
          } else {
            range.setStart(lastChild, lastChild.childNodes.length);
          }
          range.collapse(true);

          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    },

    findFirstTextNode(node) {
      // find first text node according to selected element OT-1989
      if (node.nodeType === Node.TEXT_NODE) {
        return node;
      }
      for (let i = 0; i < node.childNodes.length; i++) {
        const textNode = this.findFirstTextNode(node.childNodes[i]);
        if (textNode) {
          return textNode;
        }
      }
      return null;
    },

    insertNewElement() {
      // insert new line and set the caret OT-1989
      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const sel = window.getSelection();
      const br = document.createElement("br");
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
      const newElement = document.createElement("div");
      newElement.textContent = "";
      newElement.contentEditable = "true";

      const parent = range.commonAncestorContainer;
      if (parent.nodeType === Node.TEXT_NODE) {
        parent.parentNode.insertBefore(newElement, parent.nextSibling);
      } else {
        parent.appendChild(newElement);
      }

      range.setStart(newElement, 0);
      range.setEnd(newElement, 0);
    },

    onKeyDown(event) {
      if (event.key === "Enter") {
        // A new line was being added but it was not visible on the ui, so an extra line was added for this reason. OT-1989
        if (this.$refs.div.childNodes.length === 0 || this.$refs.div.childNodes.length === 1) {
          this.addBrElement();
        }
        event.preventDefault();
        this.insertNewElement();
      } else if (event.key === "Backspace") {
        // removes empty line for press backspace
        if (this.$refs.div.childNodes.length === 1 && this.$refs.div.childNodes[0] instanceof HTMLBRElement) {
          document.getElementById(`${this.control.id}-div`).removeChild(this.$refs.div.childNodes[0]);
        }
      }
    },
    clickEvent(e) {
      if (e.target.tagName.toLowerCase() === "span") {
        const clickedSpan = e.target;
        const spanType = clickedSpan.getAttribute("type");
        if (spanType === "arithmetic-operator" || spanType === "math-function"
            || spanType === "general-function" || spanType === "text-function"
            || spanType === "date-function" || spanType === "array-function"
            || spanType === "encode-decode-function" || spanType === "logic-function") {
          this.showModal = true;
          this.clickedFunctionName = e.target.innerText.includes("(") ? e.target.innerText.substring(0, e.target.innerText.length - 1) : e.target.innerText;
          this.$emit("expressionDrawer", this.showModal);
        }
      } else {
        this.showModal = false;
        this.$emit("expressionDrawer", this.showModal);
      }
    },
    async organizeNodes(event, isNotFirstRender = true) {
      try {
        await nextTick();
        this.$refs.div.childNodes.forEach((child, index) => {
          const nextChild = this.$refs.div.childNodes.item(index + 1);
          if (child instanceof HTMLDivElement) {
            this.setCaret(event.code);
            document.getElementById(`${this.control.id}-div`).removeChild(child);
          }
          if (nextChild) {
            if (nextChild.nodeType === child.nodeType
            && child.nodeType === Node.TEXT_NODE) {
              if (nextChild.textContent === "\u200B" || child.textContent === "\u200B") {
                document.getElementById(`${this.control.id}-div`)
                  .removeChild(child.textContent !== "\u200B" ? nextChild : child);
              } else {
                child.textContent += nextChild.textContent;
                child.textContent = child.textContent.replace("\u200B", "");
                document.getElementById(`${this.control.id}-div`)
                  .removeChild(nextChild);
              }
            }
          }
        });
      } catch (e) {
        console.log(e, "organize");
      }
      this.convertToInputString(isNotFirstRender);
    },
    getNextSibling(current?) {
      const position = getCaretPosition(this.$refs.div);
      let length = 0;
      let childNode;
      let set = false;
      let childIndex = -1;
      this.$refs.div.childNodes.forEach((child, index) => {
        if (child.nodeType === Node.TEXT_NODE) {
          length += child.data.length;
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          length += child.innerText.length;
        }
        if (position <= length && !set && position !== 0) {
          childIndex = index;
          set = true;
        } else if (position === 0) {
          childIndex = -2;
          set = true;
        }
      });
      if (childIndex > -1) {
        return current ? {
          item: this.$refs.div.childNodes.item(childIndex),
          index: childIndex,
        } : {
          item: this.$refs.div.childNodes.item(childIndex + 1),
          index: childIndex + 1,
        };
      }
      if (childIndex === -2) {
        return {
          item: this.$refs.div.childNodes.item(0),
          index: 0,
        };
      }
      return childNode;
    },
    getInputLengthToCaretPosition(index) {
      let length = 0;
      this.$refs.div.childNodes
        .forEach((node, idx) => {
          if (node.nodeType === Node.TEXT_NODE && idx < index) {
            length += node.data.length;
          } else if (node.nodeType === Node.ELEMENT_NODE && idx < index) {
            length += node.innerText.length;
          }
        });
      return length;
    },
    addItemToInput(span: HTMLSpanElement, first, isNotFirstRender = true, index) {
      const zeroWidthSpace = document.createTextNode("\u200B");
      const inputContainer = document.getElementById(`${this.control.id}-div`);
      if (index !== undefined && index >= 0 && index < inputContainer.childNodes.length) {
        this.insertBefore(span, index);
      } else {
        const current = this.getNextSibling(true);
        const nextNode = this.getNextSibling();
        if (current && current.item.nodeType === Node.TEXT_NODE && current.item.data.length > 1 && !first) {
          const [firstText, second] = this.split(current.item.data,
            getCaretPosition(this.$refs.div) - this.getInputLengthToCaretPosition(current.index));
          const firstElem = current.item;
          firstElem.data = firstText;
          inputContainer.replaceChild(firstElem, current.item);
          if (nextNode.item) {
            inputContainer.insertBefore(span, nextNode.item);
          } else {
            this.$refs.div.appendChild(span);
          }
          if (nextNode.item) {
            inputContainer.insertBefore(document.createTextNode(second), nextNode.item);
          } else {
            this.$refs.div.appendChild(document.createTextNode(second));
          }
        } else {
          this.addToDocument(span, zeroWidthSpace, nextNode ? nextNode.item : undefined, first);
        }
      }
      this.organizeNodes(isNotFirstRender);
      setCaretPosition(span, `${this.control.id}-div`);
    },
    addToDocument(span, zero, next, first) {
      if (next && (!first && first !== undefined)) {
        document.getElementById(`${this.control.id}-div`)
          .insertBefore(span, next);
        document.getElementById(`${this.control.id}-div`)
          .insertBefore(zero, span);
      } else {
        this.$refs.div.appendChild(span);
        this.$refs.div.appendChild(zero);
      }
    },
    split(text, index) {
      return [text.slice(0, index), text.slice(index)];
    },
    addFunction(item, first) {
      if (!item.hasChild) {
        const span: HTMLSpanElement = createHTMLSpanElement(item, this.hasChild(item.type), this.count, false);
        this.count++;
        const index = item.inputId !== undefined ? item.inputId + 1 : undefined;
        this.addItemToInput(span, first, true, index);
      } else {
        const span: HTMLSpanElement = createHTMLSpanElement(item, item.hasChild, this.count, false);
        this.count++;
        this.addItemToInput(span, first, true, item.inputId);
        item.childs.forEach((child) => {
          const childSpan: HTMLSpanElement = createHTMLSpanElement(child, false, this.count, false);
          this.count++;
          childSpan.classList.add(getType(item.type));
          const index = item.inputId !== undefined ? this.count : undefined;
          this.addItemToInput(childSpan, first, true, index);
        });
      }
    },
    hasChild(type) {
      return ["text-function", "math-function", "general-function"].includes(type);
    },
    addOutput(data, first, isNotFirstRender = true) {
      if (data.stepIndex > -1) {
        const innerText = `${data.stepIndex + 1}.${data.path.replaceAll("/#", "/")
          .replaceAll("@#", "/")}`;
        const output = this.outputs.find((item) => item.index === data.stepIndex);
        const outputLists = output !== undefined ? parseJSONWithStringNumbers(output.output) : undefined;
        data.tooltip = data.tooltip !== undefined && data.tooltip !== "" ? data.tooltip : getTooltipforOutput(data.path.substring(2), outputLists);
        data.tooltip = typeof data.tooltip === "object" ? JSON.stringify(data.tooltip, null) : data.tooltip;
        const span: HTMLSpanElement = createHTMLSpanElement({
          name: data.name,
          type: "output",
          tooltip: data.tooltip,
        }, false, this.count, innerText);
        this.count++;
        span.innerText = innerText;
        this.addItemToInput(span, first, isNotFirstRender, data.inputId);
      }
    },
    addMetaoutput(data, first, isNotFirstRender = true) {
      if (data.stepIndex > -1) {
        const innerText = `${data.stepIndex + 1}.${data.path.replaceAll("/#", "/")
          .replaceAll("@#", "/")}`;
        let name = data.name.split("/");
        name = name[name.length - 1];
        const span: HTMLSpanElement = createHTMLSpanElement({
          name: `/metaoutput/#ix-${data.stepIndex}@${name}`,
          type: "metaoutput",
          tooltip: data.tooltip,
        }, false, this.count, innerText);
        this.count++;
        span.innerText = innerText;
        this.addItemToInput(span, first, isNotFirstRender, data.inputId);
      }
    },
    addUserVariable(data, first, isNotFirstRender = true) {
      const innerText = `.${data.path.replaceAll("/#", "/").replaceAll("@#", "/")}`;
      const span: HTMLSpanElement = createHTMLSpanElement({
        name: `/userDefinedVariables${data.name}`,
        type: "user-defined-variable",
        tooltip: data.tooltip,
      }, false, this.count, innerText);
      this.count++;
      span.innerText = innerText;
      this.addItemToInput(span, first, isNotFirstRender, data.inputId);
    },
    addSystemVariable(data, first, isNotFirstRender = true) {
      const span: HTMLSpanElement = createHTMLSpanElement({
        name: data.name,
        tooltip: data.tooltip,
        type: "system-variable",
      }, false, this.count, data.name);
      this.count++;
      span.innerText = labelConvert(data.name);
      this.addItemToInput(span, first, isNotFirstRender, data.inputId);
    },
    normalizeLineEndings(str, normalized = "\r\n") {
      return str.replace(/\r?\n/g, normalized);
    },
    addText(item) {
      const text = document.createTextNode(this.normalizeLineEndings(item.name));
      if (item.inputId !== undefined) {
        this.insertBefore(text, item.inputId);
        this.organizeNodes(false);
      } else {
        this.$refs.div.appendChild(text);
      }
      const newOffset = text.textContent.length;
      setCaretPosition(text, `${this.control.id}-div`, newOffset);
    },
    insertBefore(item, index) {
      const zeroWidthSpace = document.createTextNode("\u200B");
      const inputContainer = document.getElementById(`${this.control.id}-div`);
      const spanElements = inputContainer.querySelectorAll("span");
      let spanArray = Array.from(spanElements);
      const functionIndex = spanArray.findIndex((span) => span.getAttribute("name").includes(this.clickedFunctionName));
      spanArray = functionIndex !== -1 ? spanArray.slice(functionIndex) : spanArray;
      const commaArray = spanArray.filter((s) => s.getAttribute("name") === ",");
      const parenthesisArray = spanArray.filter((s) => s.getAttribute("name") === ")");
      if (index !== undefined && index >= 0 && index < inputContainer.childNodes.length) {
        if (commaArray.length > 0) {
          commaArray.forEach((arr, id) => {
            if (commaArray[0].parentNode === inputContainer && index === 0) {
              if (item.nodeType === Node.TEXT_NODE) {
                inputContainer.replaceChild(item, commaArray[0].previousSibling);
              } else {
                inputContainer.insertBefore(item, commaArray[0]);
                inputContainer.insertBefore(zeroWidthSpace, item.nextSibling);
              }
            } else if (commaArray[id].parentNode === inputContainer && index === id) {
              if (item.nodeType === Node.TEXT_NODE) {
                inputContainer.replaceChild(item, commaArray[id].previousSibling);
                return;
              }
              inputContainer.insertBefore(item, commaArray[id]);
              inputContainer.insertBefore(zeroWidthSpace, item.nextSibling);
            } else if (commaArray[id].parentNode === inputContainer && index > id) {
              if (item.nodeType === Node.TEXT_NODE) {
                inputContainer.replaceChild(item, inputContainer.childNodes[inputContainer.childNodes.length - (2 * parenthesisArray.length)].previousSibling);
              }
              inputContainer.insertBefore(item, inputContainer.childNodes[inputContainer.childNodes.length - (2 * parenthesisArray.length)]);
              inputContainer.insertBefore(zeroWidthSpace, item.nextSibling);
            }
          });
        } else {
          if (item.nodeType === Node.TEXT_NODE) {
            inputContainer.replaceChild(item, parenthesisArray[0].previousSibling);
            return;
          }
          const referenceNode = parenthesisArray[0];
          inputContainer.insertBefore(item, referenceNode);
          inputContainer.insertBefore(zeroWidthSpace, item.nextSibling);
        }
      }
    },
    clearPath() {
      const evt = new Event("change", {
        bubbles: true,
        cancelable: false,
      });
      this.oldPath = "";
      this.control.data = "";
      if (this.$refs.inputField) {
        const element = document.getElementById(`${this.control.id}-input`);
        this.$refs.inputField.value = "";
        element.dispatchEvent(evt);
      }
    },
    addClickEvent() {
      try {
        if (document.getElementById(`json-form-component${this.$props.stepIdx}`)) {
          document.getElementById(`json-form-component${this.$props.stepIdx}`)
            .addEventListener("click", (event: any) => {
              const isContainsDiv = document.getElementById(`${this.control.id}-div`)
                .contains(event.target);
              const isContainsPopper = document.getElementById(`${this.control.id}-popper`)
                .contains(event.target);
              this.visible = isContainsDiv || isContainsPopper;
              this.$emit("handleClickEvent", this.visible, this.control.id);
            }, false);
        }
      } catch (e) {
        console.log(e, "click");
      }
    },
    addBrElement() {
      const br = document.createElement("br");
      document.getElementById(`${this.control.id}-div`).appendChild(br);
    },
  },
  mounted() {
    try {
      if (this.control.data) {
        const elements = JSON.parse(this.control.data);
        this.selectedVariables = elements;
        elements.forEach((item) => {
          if (item.type === "input") {
            this.addText(item);
          } else if (item.type === "output") {
            const arr = item.name.split("/#");
            this.selectedOutputId = arr.length >= 2 ? parseInt(arr[0].replace("/", ""), 10) : "";
            this.addOutput({
              name: `/${this.selectedOutputId}/#${arr[1]}`,
              path: arr.length >= 2 ? (`/#${arr[1]}`) : "",
              stepIndex: this.selectedOutputId,
            }, true, false);
          } else if (item.type === "user-defined-variable") {
            const arr = item.name.split("/#");
            const value = arr.length >= 2 ? arr[1] : "";
            let tooltip = getTooltipForUdv(value, this.userDefinedVariablesList);
            tooltip = typeof tooltip === "object" ? JSON.stringify(tooltip) : tooltip;
            this.addUserVariable({
              name: arr.length >= 2 ? (`/#${arr[1]}`) : "",
              path: arr.length >= 2 ? (`/#${arr[1]}`) : "",
              tooltip,
            }, true, false);
          } else if (item.type === "system-variable") {
            const data = { name: item.name, tooltip: this.systemValues[item.name] || "" };
            this.addSystemVariable(data);
          } else if (item.type === "metaoutput") {
            let arr = item.name.split("/#");
            arr = arr.length >= 2 ? arr[1].split("@#") : "";
            const index = arr.length >= 2 ? arr[0].split("-") : "";
            this.selectedMetaoutputId = index.length >= 2 ? parseInt(index[1], 10) : "";
            const tooltip = getTooltipForMetaoutput(arr[1], this.selectedMetaoutputId, this.metaoutputList);
            this.addMetaoutput({
              name: `/${this.selectedMetaoutputId}/#${arr[1]}`,
              path: arr.length >= 2 ? (`/#${arr[1]}`) : "",
              stepIndex: this.selectedMetaoutputId,
              tooltip,
            }, true, false);
          } else if (item.name && item.type) {
            this.addFunction(item, true);
          } else if (!item.type) {
            // show \n texts in the inputs
            this.addBrElement();
            if (elements[elements.length - 1].name === "\n") {
              this.addBrElement();
            }
          }
        });
      }
    } catch (e) {
      if (typeof this.control.data === "string") {
        this.addText({
          name: this.control.data,
        });
      }
      this.clearPath();
    }
    this.addClickEvent();
    const undoStack = [];
    const caretStack = [];
    const editorEle = document.getElementById(`${this.control.id}-div`);
    undoStack.push(editorEle.innerHTML);
    caretStack.push(getCaretPosition(editorEle));
    // MutationObserver
    const observer = new MutationObserver(() => {
      if (editorEle.innerHTML !== undoStack[undoStack.length - 1]) {
        undoStack.push(editorEle.innerHTML);
        caretStack.push(getCaretPosition(editorEle));
      }
    });
    observer.observe(editorEle, { childList: true, subtree: true, characterData: true });
    editorEle.addEventListener("paste", (e) => {
      e.stopPropagation();
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-nested-ternary
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");
      const selection = document.getSelection();
      const range = selection.getRangeAt(0);
      range.deleteContents();
      const newTextNode = document.createTextNode(pastedData);
      const currentNode = range.startContainer;
      if (currentNode.nodeType === Node.TEXT_NODE) {
        const caretPosition = range.endOffset;
        const { textContent } = currentNode;
        const updatedTextContent = textContent.substring(0, caretPosition) + pastedData + textContent.substring(caretPosition);
        currentNode.textContent = updatedTextContent;
        undoStack.push(editorEle.innerHTML);
        caretStack.push(caretPosition + pastedData.length);
        setCaretPosition(currentNode, `${this.control.id}-div`, caretPosition + pastedData.length);
      } else {
        range.insertNode(newTextNode);
        range.selectNodeContents(newTextNode);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    });
    editorEle.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        if (undoStack.length > 1) {
          undoStack.pop();
          editorEle.innerHTML = undoStack[undoStack.length - 1];
          let node = editorEle.firstChild;
          if (!node || node.nodeType !== Node.TEXT_NODE) {
            node = document.createTextNode("");
            editorEle.appendChild(node);
          }
          setCaretPosition(node, `${this.control.id}-div`);
        }
      }
    });
    window.addEventListener("keypress", (event) => {
      if (this.visible && event.key === ";") {
        event.preventDefault();
        this.addFunction({
          name: ";",
          hasChild: false,
          type: "condition-opr",
        }, false);
      }
    });
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, isStringControl),
};

